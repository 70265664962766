import debounce from 'lodash/debounce';
import { Text } from '../../../../Form/Text';
import { useTableConfig } from '../../useDataTable';

export const Filter = () => {
  const { config } = useTableConfig();

  const onFilter = value => {
    const filteredRows = config.data.filter(row =>
      JSON.stringify(Object.values(row)).includes(value)
    );
    config.setRows(filteredRows);
  };

  return (
    <Text
      name="table.filter"
      placeholder="Enter text to filter"
      onChange={debounce(onFilter, 300)}
    />
  );
};
