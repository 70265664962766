import css from './hub/tabs.module.scss';

type Props = {
  children: React.ReactNode;
  color: string;
};

export const Tab = (props: Props) => {
  const { children, color = 'primary-fill' } = props;
  return (
    <div className={css.tabContent} color={color}>
      {children}
    </div>
  );
};
