import { createContext, useContext, useEffect, useState } from 'react';
import './theme/hub.scss';

interface Props {
  children: React.ReactNode;
}

interface ThemeProps {
  theme?: string;
  setHubTheme?: (theme) => void;
}

const ThemeCtxt = createContext<ThemeProps>({});

export const useTheme = () => {
  const context = useContext(ThemeCtxt);
  return context;
};

export const ThemeContext = ({ children }: Props) => {
  const [hubTheme, setHubTheme] = useState('light');

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event =>
        event.matches ? setHubTheme('dark') : setHubTheme('light')
      );
  }, []);

  return (
    <ThemeCtxt value={{ theme: hubTheme, setHubTheme }}>
      <hub-theme theme={hubTheme}>{children}</hub-theme>
    </ThemeCtxt>
  );
};
