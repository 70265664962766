import { useActionState, useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import { FormContext } from './FormContext';
import { validate } from './hub/validate';

interface Props {
  children: React.ReactNode;
  defaults?: {};
}

export const Form = (props: Props) => {
  const { children, defaults = {} } = props;
  const [formRefs, setFormRefs] = useState({});

  const [state, formAction, isPending] = useActionState(
    (previousState, formData) => {
      const newState = { ...previousState };
      for (const pair of formData.entries()) {
        const name = pair[0];
        const errorName = `errors["${name}"]`;
        const value = pair[1];
        const constraints = get(formRefs, `${name}.constraints`);

        if (constraints) {
          const { errors } = validate(constraints, value);
          if (errors) {
            set(newState, errorName, errors);
            set(newState, name, value);
          } else {
            unset(newState, errorName);
            set(newState, name, value);
          }
        } else {
          unset(newState, errorName);
          set(newState, name, value);
        }
      }
      return newState;
    },
    defaults
  );

  const handleFormRefs = (name, refs) => {
    setFormRefs(prevState => {
      const newState = { ...prevState };
      set(newState, name, refs);
      return newState;
    });
  };

  return (
    <FormContext
      value={{ state, isPending, formAction, formRefs: handleFormRefs }}
    >
      <form action={formAction}>{children}</form>
    </FormContext>
  );
};
