import css from './hub/popover.module.scss';
interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: 'top' | 'bottom';
}

export const Popover = (props: Props) => {
  const { children, content, position = 'top' } = props;
  return (
    <span className={css.popover}>
      <hub-popover position={position} color="tertiary-f">
        {content}
      </hub-popover>
      <span style={{ position: 'relative' }}>{children}</span>
    </span>
  );
};
