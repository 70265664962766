import { createContext } from 'react';

type IDBContextType = {
  db: string;
  children: React.ReactNode;
};

export const IDBCtxt = createContext<IDBContextType>({} as IDBContextType);

export const IDBContext = ({ db, children }) => {
  return <IDBCtxt value={db}>{children}</IDBCtxt>;
};
