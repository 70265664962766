import { useSandbox } from '../Context';

export const Preview = () => {
  // eslint-disable-next-line no-useless-assignment
  const { Element } = useSandbox({ live: true });
  return (
    <div color="surface">
      <Element />
    </div>
  );
};
