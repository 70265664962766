import css from './hub/form.module.scss';

interface Props {
  label: string;
  required?: boolean;
  name?: string;
}

export const Label = ({ label, name, required }: Props) => {
  if (!label) {
    return null;
  }
  return (
    <label className={css.formLabel} htmlFor={name}>
      {label}&nbsp;
      <sup className={css.requiredAsterix} hidden={!required}>
        *
      </sup>
    </label>
  );
};
