import { useContext } from 'react';
import { SidebarContext } from './SidebarContext';
import { Drawer } from '../Drawer';
import { Grid } from '../Grid';
import css from './hub/sidebar.module.scss';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
};

export const SidebarMenu = (props: Props) => {
  const { children, content } = props;
  const context = useContext(SidebarContext);
  return (
    <>
      <div className={css.barMenuGrid}>
        <div className={css.sideMenu}>
          <div className={css.sideMenuRoutes} color="surface-f">
            {children}
          </div>
          <div className={css.sideMenuContents} color="surface">
            {content}
          </div>
        </div>
      </div>
      <div className={css.barMenuDrawer}>
        <Drawer isOpen={context.isMenuOpen} onClose={context.onToggle}>
          {children}
        </Drawer>
        <Grid color="surface">
          <Grid.Col span={12}>{content}</Grid.Col>
        </Grid>
      </div>
    </>
  );
};
