import { FormWrapper } from './FormWrapper';
import { Constraints } from './hub/types';
import { useForm } from './useForm';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label: string;
  required: boolean;
  min: string;
  max: string;
  step: string;
  constraints?: Constraints;
}
export const Date = (props: Props) => {
  const { name, constraints, label, min, max, step, required } = props;
  const [value, error, setValue] = useForm({ name, constraints, required });

  return (
    <FormWrapper name={name} label={label} error={error} required={required}>
      <input
        className={css.textRoot}
        type="date"
        id={name}
        name={name}
        value={value}
        min={min}
        max={max}
        step={step}
        color={error && 'error-f'}
        onChange={el => setValue(el.target.value)}
      />
    </FormWrapper>
  );
};
