import { app as PageApp } from './app';
import { db as PageDB } from './db';
import { usePage } from './usePage';
import { stores } from './utils/stores';

export const app = new PageApp();
export const db = new PageDB();

export const page = { app, db, stores };
export default page;

export { usePage };
