import { createContext, useContext, useMemo } from 'react';
import { generateElement } from './transpile';

interface ContextInterface {
  code: string;
  mode?: 'live' | 'sandbox' | undefined;
  newCode?: string;
  setNewCode?: (newCode) => void;
  ref?: React.RefObject<HTMLPreElement>;
  title?: string;
}
export const Context = createContext<ContextInterface>({
  code: '',
});

export const useSandbox = ({ live }) => {
  const context = useContext(Context);

  const Element = useMemo(() => {
    if (context.newCode) {
      return generateElement({ code: context.newCode });
    }
    if (live) {
      return generateElement({ code: context.code });
    }
    return null;
  }, [context.code, live, context.newCode]);

  const onCodeChange = cde => {
    context.setNewCode(cde);
  };

  return { ...context, Element, onCodeChange };
};
