import css from './hub/form.module.scss';
import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';

interface Props {
  children: React.ReactNode;
  name: string;
  label: string;
  error: string;
  required: boolean;
}

export const FormWrapper = ({
  children,
  label,
  name,
  error,
  required,
}: Props) => {
  return (
    <div className={css.formWrapper}>
      <Label label={label} required={required} name={name} />
      {children}
      <ErrorMessage error={error} />
    </div>
  );
};
