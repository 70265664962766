import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { App } from './App';
import { AppContext } from './web/context/AppContext';
// import '@destdefe/compact/styles/hub.scss';

export const root = createRoot(document.getElementById('root'));

root.render(
  <HashRouter>
    <AppContext appKey="compact">
      <App />
    </AppContext>
  </HashRouter>
);
