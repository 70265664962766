import ReactDOM from 'react-dom';
import { ThemeContext } from '../context/ThemeContext';
import './portal.module.scss';

interface Props {
  children: React.ReactNode;
  position?: 'top' | 'left' | 'bottom' | 'right';
  width?: string;
  height?: string;
  active?: boolean;
}

export const Portal = ({
  children,
  position,
  width = '100%',
  height = '100%',
  active,
}: Props) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {active
        ? ReactDOM.createPortal(
            // eslint-disable-next-line react/jsx-indent
            <hub-portal position={position} style={{ width, height }}>
              <ThemeContext>{children}</ThemeContext>
            </hub-portal>,
            document.body
          )
        : children}
    </>
  );
};
