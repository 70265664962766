import { createContext, useContext, useEffect, useState } from 'react';
import { defaultConfig, TableConfig } from './TableConfig';

export const useDataTable = (config: TableConfig) => {
  const tableConfig = { ...defaultConfig, ...config };

  const [rows, setRows] = useState(tableConfig.data);
  const [columns, setColumns] = useState(tableConfig.columns);
  const [util, setUtil] = useState();
  const [fullscreen, toggleFullScreen] = useState(false);
  const [page, setPage] = useState(tableConfig.page);
  const [maxPage, setMaxPage] = useState(0);

  const goToPrevious = () => {
    setPage(prevState => prevState - 1);
  };

  const goToNext = () => {
    setPage(prevState => prevState + 1);
  };
  useEffect(() => {
    const max = rows.length / tableConfig.pageSize;
    if (page > max) {
      setPage(max);
    }
    setMaxPage(max);
  }, [rows, tableConfig.pageSize]);

  return {
    ...tableConfig,
    data: tableConfig.data,
    rows,
    setRows,
    columns,
    setColumns,
    util,
    setUtil,
    fullscreen,
    toggleFullScreen,
    page,
    goToPrevious,
    goToNext,
    disablePrevious: page <= 1,
    disableNext: page === maxPage,
    maxPage,
  };
};

export const DataTableContext = createContext<TableConfig>({});

export const useTableConfig = () => {
  return useContext(DataTableContext);
};
