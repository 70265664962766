import { Checkbox } from './Checkbox';
import css from './hub/form.module.scss';
import { Label } from './Label';

interface Props {
  label: string;
  data: { value: string; label: string }[];
}
export const CheckboxGroup = (props: Props) => {
  const { label, data = [] } = props;

  return (
    <div className={css.formWrapper}>
      <Label label={label} />
      <div className={css.checkboxGroup}>
        {data.map(item => (
          <Checkbox name={item.value} label={item.label} key={item.value} />
        ))}
      </div>
    </div>
  );
};
