import { Label } from './Label';
import { useForm } from './useForm';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label: string;
  value: string;
}
export const Radio = (props: Props) => {
  const { name, label, value } = props;
  const [selectedValue, error, setValue] = useForm({ name });
  const uniqueName = `${name}_${value}`;
  return (
    <span>
      <input
        className={css.radioRoot}
        type="radio"
        id={uniqueName}
        name={name}
        checked={value === selectedValue}
        color={error && 'error-f'}
        onChange={() => setValue(value)}
      />
      <Label label={label} name={uniqueName} />
    </span>
  );
};
