import { FormWrapper } from './FormWrapper';
import { Constraints } from './hub/types';
import { useForm } from './useForm';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  constraints?: Constraints;
  onChange?: (el) => void;
  placeholder?: string;
}
export const Text = (props: Props) => {
  const {
    name,
    constraints,
    label,
    value: userValue,
    required,
    placeholder,
    onChange,
  } = props;
  const [value, error, setValue] = useForm({ name, constraints, required });
  const uncontrolledProps = onChange
    ? { onChange: el => onChange(el.target.value), type: 'search' }
    : {
        value,
        onChange: el => setValue(el.target.value),
      };
  return (
    <FormWrapper name={name} label={label} error={error} required={required}>
      <input
        className={css.textRoot}
        value={userValue}
        autoComplete="off"
        id={name}
        name={name}
        color={error && 'error-o'}
        placeholder={placeholder}
        {...uncontrolledProps}
      />
    </FormWrapper>
  );
};
