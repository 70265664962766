import { Accordion } from '../../../Accordion';
import { Box } from '../../../Box';
import { Button } from '../../../Button';
import { Card } from '../../../Card';
import { CodeBox } from '../../../CodeBox';
import { DataTable, useDataTable } from '../../../DataTable';
import { Dialog, useDialog } from '../../../Dialog';
import { Drawer } from '../../../Drawer';
import { Flex } from '../../../Flex';
import { Form } from '../../../Form';
import { CheckboxGroup } from '../../../Form/CheckboxGroup';
import { Date } from '../../../Form/Date';
import { RadioGroup } from '../../../Form/RadioGroup';
import { Select } from '../../../Form/Select';
import { Submit } from '../../../Form/Submit';
import { Text } from '../../../Form/Text';
import { TextArea } from '../../../Form/TextArea';
import { Grid } from '../../../Grid';
import { Icon } from '../../../Icon';
import { icons } from '../../../Icon/hub/icons';
import { Popover } from '../../../Popover';
import { Sidebar } from '../../../Sidebar';
import { Stack } from '../../../Stack';
import { Tabs } from '../../../Tabs';
import { ToastContext, useToast } from '../../../Toast';
import mock from '../../../tools/mock';

const scope = {
  Box,
  Flex,
  Sidebar,
  Grid,
  Button,
  Card,
  Stack,
  Form,
  Text,
  Submit,
  TextArea,
  Select,
  Date,
  CheckboxGroup,
  RadioGroup,
  Tabs,
  Drawer,
  CodeBox,
  Icon,
  icons,
  DataTable,
  useDataTable,
  mock,
  Popover,
  ToastContext,
  useToast,
  Dialog,
  useDialog,
  Accordion,
};

export default scope;
