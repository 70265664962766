import { Content } from './hub/Content';
import { Header } from './hub/Header';
import { Item } from './hub/Item';
import './hub/accordion.module.scss';

interface Props {
  children: React.ReactNode;
  Header?: React.FC;
  Item?: React.FC;
}

export const Accordion = ({ children }: Props) => {
  return <hub-accordion>{children}</hub-accordion>;
};

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Content = Content;
