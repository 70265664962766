import { Children } from 'react';
import css from './hub/stack.module.scss';

interface Props {
  children: React.ReactNode;
}
export const StackFlat = ({ children }: Props) => {
  return (
    <div className={css.stackFlat}>
      {Children.map(children, child => {
        return <span className={css.stackFlatChild}>{child}</span>;
      })}
    </div>
  );
};
