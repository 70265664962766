import { createContext } from 'react';

type FormContextType = {
  state?: {};
  isPending?: boolean;
  formAction?: (payload) => void;
  formRefs?: (name, payload) => void;
};

const FormContext = createContext<FormContextType>({});

export { FormContext };
