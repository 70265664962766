export const defaultConfig = {
  data: [],
  columns: [],
  height: '210px',
  page: 1,
  pageSize: 5,
};

type Columns = {
  dataKey: string;
  label: string;
  fixed?: boolean;
  sort?: 'asc' | 'desc';
  download?: boolean;
  draggable?: boolean;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
};
type Config = {
  data: {}[];
  rows: {}[];
  header: string;
  footer: string;
  page: number;
  pageSize: number;
  disablePrevious: boolean;
  disableNext: boolean;
  goToNext: () => void;
  goToPrevious: () => void;
  columns: Columns[];
  height?: number;
  util: string;
  fullscreen: boolean;
  setUtil: (util: string) => void;
  setRows: (rows: {}[]) => void;
  toggleFullScreen: (toggle) => void;
};

export interface TableConfig {
  config?: Config;
}
