import { useBox } from './hub';

interface Props {
  children: React.ReactNode;
  width: string;
  height: string;
  color: string;
}

export const Box = (props: Props) => {
  const { children, color = 'primary' } = props;
  const { boxStyle } = useBox(props);
  return (
    <hub-box style={boxStyle} color={color}>
      {children}
    </hub-box>
  );
};
