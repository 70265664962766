import { useState } from 'react';
import { Button } from '../../Button';
import { Icon } from '../../Icon';

export const Header = ({ children }) => {
  const [status, setStatus] = useState('closed');

  const handleStatus = () => {
    const currStatus = status;
    setStatus('animate');
    setTimeout(() => {
      setStatus(() => {
        return currStatus === 'closed' ? 'open' : 'closed';
      });
    }, 75);
  };

  return (
    <hub-accordion-header color="secondary" font="label-large" status={status}>
      <div className="heading">{children}</div>
      <div className="close">
        <Button color="tertiary-f" size="xs" onClick={handleStatus}>
          <Icon size="xs">{status === 'open' ? 'up' : 'down'}</Icon>
        </Button>
      </div>
    </hub-accordion-header>
  );
};
