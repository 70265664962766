export const stores = {
  form: {
    primaryOptions: { keyPath: 'formId', autoIncrement: true },
    indexes: [
      {
        name: 'name',
        keyPath: 'name',
      },
    ],
  },
  api: {
    primaryOptions: { keyPath: 'id', autoIncrement: true },
    indexes: [
      {
        name: 'path',
        keyPath: 'path',
      },
    ],
  },
};
