import { Grid } from '../../../Grid';
import { Editor } from '../../hub/Code/Editor';
import { Utils } from '../../hub/Code/Utils';

export const Reader = () => {
  return (
    <Grid color="surface-f">
      <Grid.Col span={12}>
        <Utils />
      </Grid.Col>
      <Grid.Col span={12}>
        <Editor />
      </Grid.Col>
    </Grid>
  );
};
