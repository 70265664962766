import { Label } from './Label';
import { Radio } from './Radio';
import css from './hub/form.module.scss';

interface Props {
  label: string;
  name: string;
  data: { value: string; label: string }[];
}
export const RadioGroup = (props: Props) => {
  const { label, name, data = [] } = props;

  return (
    <div className={css.formWrapper}>
      <Label label={label} />
      <div className={css.checkboxGroup}>
        {data.map(item => (
          <Radio
            name={name}
            label={item.label}
            value={item.value}
            key={item.value}
          />
        ))}
      </div>
    </div>
  );
};
