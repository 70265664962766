import { createElement, useMemo } from 'react';
import { TableConfig } from './hub/TableConfig';
import { DataTableContext } from './hub/useDataTable';
import { utils } from './hub/utils';
import { Button } from '../Button';
import { Form } from '../Form';
import { Icon } from '../Icon';
import { Portal } from '../Portal';
import './hub/datatable.module.scss';

export const DataTable = ({ config }: TableConfig) => {
  const Table = useMemo(() => {
    const endIndex = config.page * config.pageSize;
    const startIndex = endIndex - config.pageSize;
    const paginatedRows = config.rows.filter(
      (row, rowIndex) => rowIndex >= startIndex && rowIndex < endIndex
    );
    return config.columns.map(column => (
      <hub-table-column
        key={column.dataKey}
        style={{
          flex: `0 0 ${column.width}px`,
          height: config.fullscreen ? '100%' : config.height,
        }}
      >
        <hub-table-head color="primary-fd">{column.label}</hub-table-head>
        {paginatedRows.map(row => (
          <hub-table-cell key={`cell_${column.dataKey}_${row[column.dataKey]}`}>
            {row[column.dataKey]}
          </hub-table-cell>
        ))}
      </hub-table-column>
    ));
  }, [config.rows, config.fullscreen, config.page]);

  return (
    <DataTableContext value={{ config }}>
      <Form>
        <Portal position="top" active={config.fullscreen}>
          <hub-table>
            <hub-table-header color="secondary-f">
              <div>{config.header}</div>
              <div>
                <Button
                  size="xs"
                  color={config.util === 'filter' ? 'surface-f' : 'tertiary-f'}
                  onClick={() => config.setUtil('filter')}
                >
                  <Icon size="xs">filter</Icon>
                </Button>
                <Button
                  size="xs"
                  color={config.util === 'tools' ? 'surface-f' : 'tertiary-f'}
                  onClick={() => config.setUtil('tools')}
                >
                  <Icon size="xs">plow</Icon>
                </Button>
                <Button
                  size="xs"
                  color={config.fullscreen ? 'surface-f' : 'tertiary-f'}
                  onClick={() => {
                    config.toggleFullScreen(prevState => !prevState);
                  }}
                >
                  <Icon size="xs">
                    {config.fullscreen ? 'full_screen_exit' : 'full_screen'}
                  </Icon>
                </Button>
              </div>
            </hub-table-header>
            {config.util && config.util !== 'full_screen' && (
              <hub-table-utils color="surface">
                {createElement(utils[config.util])}
                <Button size="xs" onClick={() => config.setUtil('')}>
                  <Icon size="xs">close</Icon>
                </Button>
              </hub-table-utils>
            )}
            <hub-table-body color="surface">{Table}</hub-table-body>
            <hub-table-footer color="secondary-f">
              <div>{config.footer}</div>
              <div>
                <Button
                  size="sm"
                  disabled={config.disablePrevious}
                  color="tertiary-f"
                  onClick={config.goToPrevious}
                >
                  Previous
                </Button>
                <Button
                  size="sm"
                  color="tertiary-f"
                  disabled={config.disableNext}
                  onClick={config.goToNext}
                >
                  Next
                </Button>
              </div>
            </hub-table-footer>
          </hub-table>
        </Portal>
      </Form>
    </DataTableContext>
  );
};
