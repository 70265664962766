export class app {
  constructor() {
    this.appKey = '';
  }
  init = (appKey, data = {}) => {
    this.appKey = appKey;
    this[this.appKey] = {};
    Object.keys(data).forEach(item => {
      this[this.appKey][item] = data[item];
    });
  };
}
