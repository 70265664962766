import { Label } from './Label';
import { useForm } from './useForm';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label: string;
}
export const Checkbox = (props: Props) => {
  const { name, label } = props;
  const [value, error, setValue] = useForm({ name });

  return (
    <span>
      <input
        className={css.checkboxRoot}
        type="checkbox"
        id={name}
        name={name}
        checked={value === 'true'}
        color={error && 'error-o'}
        onChange={el => setValue(el.target.checked)}
      />
      <Label label={label} name={name} />
    </span>
  );
};
