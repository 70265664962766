//  Check IDB Support
export const checkIDBSupport = () => {
  if (!window.indexedDB) {
    return false;
  }
  return true;
};

//  Check IDB Exists
export const checkIDBExists = async dbName => {
  const isExisting = (await window.indexedDB.databases())
    .map(db => db.name)
    .includes(dbName);
  return isExisting;
};

export const getDB = async () => {
  const dbs = await window.indexedDB.databases();
  return dbs;
};

//  Get Database stores
export const getStores = async dbName => {
  const stores = (await window.indexedDB.databases()).filter(
    db => db.name === dbName
  );
  return stores;
};

export const getDataFunctions = (db, storeKey) => {
  return {
    get: async (form, defaultVal = '') => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise(resolve => {
        const data = store.get(form);
        data.onsuccess = event => {
          resolve(event.target.result || defaultVal);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    getAll: async () => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise(resolve => {
        const data = store.getAll();
        data.onsuccess = event => {
          resolve(event.target.result);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    add: async form => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise((resolve, reject) => {
        const request = store.add(form);
        request.onsuccess = event => {
          resolve(event.target.result);
        };
        request.onerror = event => {
          reject(event.target.error);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    addMulti: async data => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise((resolve, reject) => {
        data.forEach(item => {
          const request = store.add(item);
        });

        request.onsuccess = event => {
          resolve(event.target.result);
        };
        request.onerror = event => {
          reject(event.target.error);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    edit: async (form, primaryKey) => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise((resolve, reject) => {
        const request = store.put(form, primaryKey);
        request.onsuccess = event => {
          const data = store.getAll();
          data.onsuccess = event => {
            resolve(event.target.result);
          };
        };
        request.onerror = event => {
          reject(event.target.error);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    clear: () => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      return store.clear();
    },
    delete: async form => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise((resolve, reject) => {
        const request = store.delete(form);
        request.onsuccess = async event => {
          const storePromise = new Promise(resolve => {
            const data = store.getAll();
            data.onsuccess = event => {
              resolve(event.target.result);
            };
          });
          const storeData = await storePromise;
          resolve(storeData);
        };
        request.onerror = event => {
          reject(event.target.error);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
    cursor: async (query, direction) => {
      const tx = db.transaction(storeKey, 'readwrite');
      const store = tx.objectStore(storeKey);
      const storePromise = new Promise((resolve, reject) => {
        const request = store.openCursor(query, direction);
        request.onsuccess = async event => {
          resolve(event.target.result.value || []);
        };
        request.onerror = event => {
          reject(event.target.error);
        };
      });
      const storeData = await storePromise;
      return storeData;
    },
  };
};

export const initIDB = async (dbName, stores) => {
  const isIDBSupported = checkIDBSupport();
  if (isIDBSupported) {
    try {
      const idbResponse = await Promise.resolve(createIDB(dbName, 1, stores));
      return idbResponse;
    } catch (err) {
      throw new Error('IDB creation failed', err.message);
    }
  }
};

export const openIDB = (dbName, dbVersion) => {
  try {
    const request = window.indexedDB.open(dbName, dbVersion);
    request.onerror = event => {
      return `Database ${dbName} creation has experienced an error ${event.target.errorCode}`;
    };
    request.onsuccess = event => {
      return `Database ${dbName} has been created successfully`;
    };
  } catch (err) {
    return err;
  }
};

export const getOrCreateStores = async (event, stores) => {
  const db = event.target.result;
  const storeKeys = Object.keys(stores);
  const storeResponse = {};
  await storeKeys.reduce(async (prevPromise, storeKey) => {
    await prevPromise;
    const store = stores[storeKey];
    if (!db.objectStoreNames.contains(storeKey)) {
      const primaryOptions = stores[storeKey].primaryOptions || {
        autoIncrement: true,
      };
      const objectStoreResult = db.createObjectStore(storeKey, primaryOptions);
      if (store.indexes) {
        store.indexes.forEach(index => {
          objectStoreResult.createIndex(
            index.name,
            index.keyPath,
            index.parameters
          );
        });
      }
    }
    storeResponse[storeKey] = getDataFunctions(db, storeKey);
  }, Promise.resolve());
  return storeResponse;
};

export const createIDB = (dbName, dbVersion, stores) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = event => {
      reject(event);
    };
    request.onupgradeneeded = async event => {
      const storeResponse = await getOrCreateStores(event, stores);
      resolve(storeResponse);
    };
    request.onsuccess = async event => {
      const stores = {};
      Object.values(event.target.result.objectStoreNames).forEach(storeName => {
        stores[storeName] = {};
      });
      const storeResponse = await getOrCreateStores(event, stores);
      resolve(storeResponse);
    };
  });
};

export const upgradeIDB = (dbName, dbVersion, onUpgrade) => {
  try {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = event => {
      // Handle errors.
    };
    request.onupgradeneeded = event => {
      if (onUpgrade) {
        const db = event.target.result;
        onUpgrade(db);
      }
    };
  } catch (err) {
    return err;
  }
};

export const addItem = (dbName, dbVersion, objectStore, data) => {
  const request = indexedDB.open(dbName, dbVersion);
  request.onerror = event => {};
  request.onsuccess = event => {
    const db = event.target.result;
    const store = db
      .transaction([objectStore], 'readwrite')
      .objectStore(objectStore);
    const request = store.add(data);
    request.onerror = event => {};
  };
};

export const addItems = (dbName, dbVersion, objectStore, data) => {
  try {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = event => {
      // Handle errors.
    };
    request.onsuccess = event => {
      const db = event.target.result;
      const store = db
        .transaction([objectStore], 'readwrite')
        .objectStore(objectStore);
      data.forEach(item => {
        const request = store.add(item);
      });
    };
    request.onerror = event => {};
  } catch (err) {
    return err;
  }
};

export const getItems = (dbName, dbVersion, objectStore, data) => {
  try {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = event => {
      // Handle errors.
    };
    request.onupgradeneeded = event => {
      const db = event.target.result;
      db.transaction(objectStore).objectStore(objectStore).get(data).onsuccess =
        event => {
          return event.target.result;
        };
    };
  } catch (err) {
    return err;
  }
};

export const putItem = (dbName, dbVersion, objectStore, data) => {
  try {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = event => {
      // Handle errors.
    };
    request.onupgradeneeded = event => {
      const db = event.target.result;
      db.transaction(objectStore).objectStore(objectStore).get(data).onsuccess =
        event => {
          return event.target.result;
        };
    };
  } catch (err) {
    return err;
  }
};

export const getAll = (dbName, dbVersion, objectStore, cb) => {
  const request = indexedDB.open(dbName, dbVersion);
  request.onsuccess = event => {
    const db = event.target.result;
    db.transaction(objectStore).objectStore(objectStore).getAll().onsuccess =
      event => {
        cb(event.target.result);
      };
  };
};

export const getData = (dbName, dbVersion, objectStore, keyName, cb) => {
  const request = indexedDB.open(dbName, dbVersion);
  request.onsuccess = event => {
    const db = event.target.result;
    db.transaction(objectStore).objectStore(objectStore).getAll().onsuccess =
      event => {
        const colData = event.target.result.map(it => it[keyName]).sort();
        cb(colData);
      };
  };
};

export const idb = {
  checkIDBSupport,
  checkIDBExists,
  initIDB,
  getStores,
  createIDB,
};
