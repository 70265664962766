import { StackFlat } from './StackFlat';
import { StackVertical } from './StackVertical';

interface StackType {
  Flat: React.FC;
  Vertical: React.FC;
}

const Stack: StackType = { Flat: StackFlat, Vertical: StackVertical };

export { Stack };
