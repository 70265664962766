/* eslint-disable */

const matrix = (m, n) => {
  const data = [];
  for (let i = 0; i < m; i++) {
    data[i] = {};
    for (let j = 0; j < n; j++) {
      const col = `column_${j}`;
      data[i][col] = `${i}${j}`;
    }
  }
  return data;
};

export default { matrix };
