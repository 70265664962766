import { useMemo, useRef, useState } from 'react';
import { Live } from './modes/Live';
import { Reader } from './modes/Reader';
import { Sandbox } from './modes/Sandbox';
import { Context } from '../hub/Context';

type Props  = {
  children: React.ReactNode;
  metastring?: string;
}
type MetaString = {
  mode?: string;
  title?: string;
};
export const Code = ({ children, metastring = '' }: Props) => {
  const { mode, title }: MetaString = useMemo(() => {
    const outArray = {};
    metastring.split(',').forEach(item => {
      const propArray = item.split('=');
      outArray[propArray[0]] = propArray[1]?.replace(/"/g, '');
    });
    return outArray;
  }, [metastring]);

  const code = String(children).trim();
  const [newCode, setNewCode] = useState();
  const ref = useRef(null);

  if (mode === 'reader') {
    return (
      <Context value={{ code, title }}>
        <Reader />
      </Context>
    );
  }

  if (mode === 'sandbox') {
    return (
      <Context value={{ code, mode, newCode, setNewCode, ref, title }}>
        <Sandbox />
      </Context>
    );
  }

  return (
    <Context value={{ code, title }}>
      <Live />
    </Context>
  );
};
