import { FormWrapper } from './FormWrapper';
import { Constraints } from './hub/types';
import { useForm } from './useForm';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label?: string;
  rows?: number;
  resize?: 'none' | 'horizontal' | 'vertical';
  required?: boolean;
  constraints?: Constraints;
}
export const TextArea = (props: Props) => {
  const { name, constraints, label, rows, resize, required } = props;
  const [value, error, setValue] = useForm({ name, constraints, required });

  return (
    <FormWrapper name={name} label={label} error={error} required={required}>
      <textarea
        className={css.textAreaRoot}
        id={name}
        name={name}
        value={value}
        color={error && 'error-f'}
        rows={rows}
        data-resize={resize}
        onChange={el => setValue(el.target.value)}
      />
    </FormWrapper>
  );
};
