import { Grid } from '../../../Grid';
import { Preview } from '../../hub/Code/Preview';

export const Live = () => {
  return (
    <Grid color="surface-f">
      <Grid.Col span={12}>
        <Preview />
      </Grid.Col>
    </Grid>
  );
};
