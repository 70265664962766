import { useState } from 'react';

export const useDownload = () => {
  const [download, setDownload] = useState(true);
  const onDownload = (content, filename = 'download') => {
    setDownload(false);
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setTimeout(() => {
      setDownload(true);
    }, 250);
  };
  return { download, onDownload };
};
