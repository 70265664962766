import css from './hub/sidebar.module.scss';

type Props = {
  children: React.ReactNode;
};

export const SidebarDivider = (props: Props) => {
  const { children } = props;
  return (
    <>
      <div className={css.divider}>
        <div>{children}</div>
      </div>
    </>
  );
};
