const preBuiltContraints = {
  required: {
    condition: (value, constraint) => constraint && !value,
    message: () => 'Required value.',
  },
  minLength: {
    condition: (value, constraint) => value.length < constraint,
    message: (value, constraint) => `Minimum length is ${constraint}.`,
  },
  maxLength: {
    condition: (value, constraint) => value.length > constraint,
    message: (value, constraint) => `Maximum length is ${constraint}.`,
  },
  minValue: {
    condition: (value, constraint) => value < constraint,
    message: (value, constraint) => `Minimum value is ${constraint}.`,
  },
  maxValue: {
    condition: (value, constraint) => value > constraint,
    message: (value, constraint) => `Maximum value is ${constraint}.`,
  },
};

const validate = (constraints = {}, value) => {
  const constraintKeys = Object.keys(constraints);
  const errors = [];
  const append = constraintKeys.some(constraint => {
    const constraintType = typeof constraint;
    let criteria = preBuiltContraints[constraint] || constraints[constraint];
    const constraintValue = constraints[constraint];
    const hasError = criteria.condition(value, constraintValue);
    if (hasError) {
      const message = criteria.message(value, constraintValue);
      errors.push(message);
    }
  });
  return { append, errors: errors.join(' ') };
};

export { validate };
