import { useMemo } from 'react';
import { FormWrapper } from './FormWrapper';
import { Constraints } from './hub/types';
import { useForm } from './useForm';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Text } from '../Form/Text';
import { Grid } from '../Grid';
import { usePageFilter } from '../hooks/usePageFilter';
import { Icon } from '../Icon';
import css from './hub/form.module.scss';

interface Props {
  name: string;
  label?: string;
  data?: {}[];
  labelKey?: string;
  valueKey?: string;
  required?: boolean;
  constraints?: Constraints;
}
export const Select = (props: Props) => {
  const {
    name,
    constraints,
    label,
    data = [],
    valueKey = 'value',
    labelKey = 'label',
    required,
  } = props;
  const [value, error, setValue] = useForm({ name, constraints, required });
  const selectedLabel = useMemo(
    () => data.find(el => el[valueKey] === value)?.[labelKey],
    [value]
  );

  const {
    rows,
    page,
    filter,
    goToPrevious,
    goToNext,
    disablePrevious,
    disableNext,
    onFilter,
  } = usePageFilter(data);

  const ariaControl = `${name}.options`;
  return (
    <FormWrapper name={name} label={label} error={error} required={required}>
      <div className={css.selectWrapper}>
        <input
          className={css.selectRoot}
          id={name}
          name={name}
          value={selectedLabel}
          color={error && 'error-f'}
          role="combobox"
          aria-controls={ariaControl}
          data-active-option={`${name}_${value}`}
          aria-autocomplete="list"
          aria-haspopup="listbox"
          aria-expanded
          readOnly
        />
        <ul id={ariaControl} role="listbox" className={css.optionWrapper}>
          <li>
            <Grid>
              <Grid.Col span={9}>
                <Text
                  name="select.filter"
                  placeholder="Enter text to filter"
                  value={filter}
                  onChange={onFilter}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex justify="flex-end" align="center">
                  <Button
                    size="xs"
                    color="tertiary-f"
                    disabled={disablePrevious}
                    onClick={goToPrevious}
                  >
                    <Icon size="xs">left</Icon>
                  </Button>
                  <hub-div font="title-medium">
                    &nbsp;
                    <strong>{page}</strong>
                    &nbsp;
                  </hub-div>
                  <Button
                    size="xs"
                    color="tertiary-f"
                    disabled={disableNext}
                    onClick={goToNext}
                  >
                    <Icon size="xs">right</Icon>
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </li>
          {rows.map(item => (
            <li
              key={item[valueKey]}
              tabIndex={-1}
              className={css.option}
              role="option"
              id={`${name}_${item[valueKey]}`}
              onClick={() => setValue(item[valueKey])}
            >
              {item[labelKey]}
            </li>
          ))}
        </ul>
      </div>
    </FormWrapper>
  );
};
