import { CodeBox } from '../../../CodeBox';
import { useSandbox } from '../Context';

export const Editor = () => {
  const { code, mode, onCodeChange, ref } = useSandbox({ live: false });
  const live = mode === 'sandbox';

  return (
    <CodeBox
      ref={ref}
      code={code}
      language="javascript"
      live={live}
      hideCopy
      onCodeChange={onCodeChange}
    />
  );
};
