import React, { Component } from 'react';

const errorBoundary = (Element, errorCallback = () => null) => {
  return class ErrorBoundary extends Component {
    componentDidCatch(error) {
      errorCallback(error);
    }

    render() {
      if (typeof Element === 'function') {
        return <Element />;
      } else if (React.isValidElement(Element)) {
        return Element;
      }
      return null;
    }
  };
};

export default errorBoundary;
