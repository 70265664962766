import { useEffect, useState } from 'react';

const pageSize = 4;
export const usePageFilter = data => {
  const [rows, setRows] = useState(data);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterSet, setFilterSet] = useState(data);
  const [maxPage, setMaxPage] = useState(0);

  const onFilter = value => {
    if (value) {
      const filteredRows = data.filter(row =>
        JSON.stringify(Object.values(row))
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setFilter(value);
      setFilterSet(filteredRows);
    } else {
      setFilter(value);
      setFilterSet(data);
    }
  };

  const goToPrevious = () => {
    setPage(prevState => prevState - 1);
  };

  const goToNext = () => {
    setPage(prevState => prevState + 1);
  };

  useEffect(() => {
    const max = Math.ceil(filterSet.length / pageSize);
    let currPage = page;
    if (page > max) {
      currPage = max;
      setPage(max);
    }
    setMaxPage(max);
    const endIndex = currPage * pageSize;
    const startIndex = endIndex - pageSize;
    const paginatedRows = filterSet.filter(
      (row, rowIndex) => rowIndex >= startIndex && rowIndex < endIndex
    );
    setRows(paginatedRows);
  }, [page, pageSize, filterSet]);

  return {
    rows,
    page,
    filter,
    goToPrevious,
    goToNext,
    disablePrevious: page <= 1,
    disableNext: page === maxPage,
    onFilter,
  };
};
