import { useState } from 'react';

export const useCopy = () => {
  const [copy, setCopy] = useState(true);
  const onCopy = content => {
    navigator.clipboard.writeText(content);
    setCopy(false);
    setTimeout(() => {
      setCopy(true);
    }, 250);
  };
  return { copy, onCopy };
};
