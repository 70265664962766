import { useEffect, useMemo } from 'react';
import Prism from 'prismjs';
import { Button } from '../Button';
import { useCopy } from '../hooks/useCopy';
import { Icon } from '../Icon';
import css from './hub/codebox.module.scss';
import './hub/codebox.prism.scss';

interface Props {
  code: string;
  language: string;
  live?: boolean;
  hideCopy?: boolean;
  onCodeChange?: (code) => void;
  ref?: React.RefObject<HTMLPreElement>;
}

export const CodeBox = ({
  code,
  language,
  live,
  hideCopy,
  onCodeChange,
  ref,
}: Props) => {
  const { copy, onCopy } = useCopy();
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const transformedCode = useMemo(() => code.trim(), [code]);

  const onInput = el => {
    if (onCodeChange) {
      onCodeChange(el.target.innerText);
    }
  };
  return (
    <hub-codebox>
      <pre
        ref={ref}
        contentEditable={live}
        spellCheck="false"
        suppressContentEditableWarning
        onInput={onInput}
      >
        <code className={`${css.code} language-${language}`}>
          {transformedCode}
        </code>
        {!hideCopy && (
          <div className={css.copy}>
            <Button shape="plain" onClick={onCopy}>
              <Icon>{copy ? 'copy' : 'copy'}</Icon>
            </Button>
          </div>
        )}
      </pre>
    </hub-codebox>
  );
};
