import { Grid } from '../../../Grid';
import { Editor } from '../../hub/Code/Editor';
import { Preview } from '../../hub/Code/Preview';
import { Utils } from '../../hub/Code/Utils';

export const Sandbox = () => {
  return (
    <Grid color="surface-f">
      <Grid.Col span={12}>
        <Utils />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
        <Editor />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 6 }}>
        <Preview />
      </Grid.Col>
    </Grid>
  );
};
