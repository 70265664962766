import { idb } from './utils/idb';

export class db {
  constructor() {
    this.app = '';
  }

  init = (appKey, stores) => {
    this.promise = new Promise((resolve, reject) => {
      this.app = appKey;
      this[appKey] = {
        dbName: appKey,
        stores,
      };
      try {
        idb.initIDB(appKey, stores).then(idbResponse => {
          const idbKeys = Object.keys(idbResponse);
          idbKeys.forEach(idbKey => {
            const idbData = idbResponse[idbKey];
            this[appKey][idbKey] = idbData;
          });
          resolve(this);
        });
      } catch (err) {
        console.log('IDB initialization error', err);
        reject(err.message);
      }
    });
    return this.promise;
  };
}
