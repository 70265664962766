import { useDownload } from '../../../..//hooks/useDownload';
import { Button } from '../../../../Button';
import { useCopy } from '../../../../hooks/useCopy';
import { useTableConfig } from '../../useDataTable';

export const Tools = () => {
  const { config } = useTableConfig();
  const { copy, onCopy } = useCopy();
  const { download, onDownload } = useDownload();
  return (
    <div>
      <Button size="sm" shape="plain" onClick={window.print}>
        Print
      </Button>
      <Button
        size="sm"
        shape="plain"
        onClick={() => onDownload(JSON.stringify(config.rows, null, 2))}
      >
        {download ? 'Download' : 'Downloading'}
      </Button>
      <Button
        size="sm"
        shape="plain"
        onClick={() => onCopy(JSON.stringify(config.rows, null, 2))}
      >
        {copy ? 'Copy' : 'Copied'}
      </Button>
    </div>
  );
};
