import { Button } from '../../Button';
import { Icon } from '../../Icon';

export const Header = ({ children }) => {
  return (
    <hub-dialog-header color="secondary" font="title-large">
      <div className="heading">{children}</div>
      <div className="close">
        <Button color="tertiary-f" size="xs">
          <Icon size="xs">close</Icon>
        </Button>
      </div>
    </hub-dialog-header>
  );
};
