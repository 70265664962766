import { Button } from '../../../Button';
import { useCopy } from '../../../hooks/useCopy';
import { useSandbox } from '../Context';
import css from './code.module.scss';

export const Utils = () => {
  const { copy, onCopy } = useCopy();
  const { code, title } = useSandbox({ live: false });

  // const onReset = () => {
  //   setNewCode(code);
  // };
  return (
    <div className={css.utils}>
      <hub-code-utile font="label-large">{title}</hub-code-utile>
      <div>
        {/* <Button shape="plain" size="sm" onClick={onReset}>
          Reset
        </Button> */}
        <Button shape="plain" size="sm">
          Download
        </Button>
        <Button shape="plain" size="sm" onClick={() => onCopy(code)}>
          {copy ? 'Copy' : 'Copied'}
        </Button>
      </div>
    </div>
  );
};
