import React from 'react';
import compose from './compose';
import errorBoundary from './errorBoundary';
import evalCode from './evalCode';
import scope from './scope';
import transform from './transform';

/** Credit; react-live */
const jsxConst = 'const _jsxFileName = "";';
const trimCode = code => code.trim().replace(/;$/, '');
const spliceJsxConst = code => code.replace(jsxConst, '').trim();
const addJsxConst = code => jsxConst + code;
const wrapReturn = code => `return (${code})`;

export const generateElement = ({ code = '' }, errorCallback = () => null) => {
  const firstPassTransforms = ['jsx'];
  firstPassTransforms.push('typescript');
  const transformed = compose(
    addJsxConst,
    transform({ transforms: ['imports'] }),
    spliceJsxConst,
    trimCode,
    transform({ transforms: firstPassTransforms }),
    wrapReturn,
    trimCode
  )(code);

  return errorBoundary(
    evalCode(transformed, { React, ...scope }),
    errorCallback
  );
};
