import { Children } from 'react';
import css from './hub/stack.module.scss';

export const StackVertical = ({ children }) => {
  return (
    <div className={css.stackVertical}>
      {Children.map(children, child => {
        return <div className={css.stackVerticalChild}>{child}</div>;
      })}
    </div>
  );
};
