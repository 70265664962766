import { lazy } from 'react';
import { Logo } from './Logo';

const Dashboard = lazy(() => import('./Dashboard.mdx'));
const Icons = lazy(() => import('./Icons.mdx'));
const Colors = lazy(() => import('./Colors.mdx'));
const Typography = lazy(() => import('./Typography.mdx'));
const Accordion = lazy(() => import('./components/Accordion.mdx'));
const Box = lazy(() => import('./components/Box.mdx'));
const Button = lazy(() => import('./components/Button.mdx'));
const Card = lazy(() => import('./components/Card.mdx'));
const DataGrid = lazy(() => import('./components/DataGrid.mdx'));
const Sidebar = lazy(() => import('./components/Sidebar.mdx'));
const CodeBox = lazy(() => import('./components/CodeBox.mdx'));
const Popover = lazy(() => import('./components/Popover.mdx'));
const Dialog = lazy(() => import('./components/Dialog.mdx'));
const Toast = lazy(() => import('./components/Toast.mdx'));
const Flex = lazy(() => import('./display/Flex.mdx'));
const Grid = lazy(() => import('./display/Grid.mdx'));
const Stack = lazy(() => import('./display/Stack.mdx'));
const Tabs = lazy(() => import('./display/Tabs.mdx'));
const Form = lazy(() => import('./form/Form.mdx'));

const config = [
  {
    label: <Logo />,
    href: '#/compact',
    path: '/',
    element: <Dashboard />,
  },
  {
    label: 'Typography',
    href: '#/compact/typography',
    path: 'typography/*',
    element: <Typography />,
  },
  {
    label: 'Colors',
    href: '#/compact/colors',
    path: 'colors/*',
    element: <Colors />,
  },
  {
    label: 'Icons',
    href: '#/compact/icons',
    path: 'icons/*',
    element: <Icons />,
  },
  {
    label: 'Components',
    data: [
      {
        label: 'Accordion',
        element: <Accordion />,
        path: 'accordion/*',
        href: '#/compact/accordion',
      },
      {
        label: 'Box',
        element: <Box />,
        path: 'box/*',
        href: '#/compact/box',
      },
      {
        label: 'Button',
        path: 'button/*',
        href: '#/compact/button',
        element: <Button />,
      },
      {
        label: 'Card',
        element: <Card />,
        path: 'card/*',
        href: '#/compact/card',
      },
      {
        label: 'DataGrid',
        element: <DataGrid />,
        path: 'datagrid/*',
        href: '#/compact/datagrid',
      },
      {
        label: 'Sidebar',
        element: <Sidebar />,
        path: 'sidebar/*',
        href: '#/compact/sidebar',
      },
      {
        label: 'CodeBox',
        element: <CodeBox />,
        path: 'codeBox/*',
        href: '#/compact/codeBox',
      },
      {
        label: 'Dialog',
        element: <Dialog />,
        path: 'dialog/*',
        href: '#/compact/dialog',
      },
    ],
  },
  {
    label: 'Form',
    data: [
      {
        label: 'Form',
        element: <Form />,
        path: 'form/*',
        href: '#/compact/form',
      },
    ],
  },
  {
    label: 'Display',
    data: [
      {
        label: 'Flex',
        element: <Flex />,
        path: 'flex/*',
        href: '#/compact/flex',
      },
      {
        label: 'Grid',
        element: <Grid />,
        path: 'grid/*',
        href: '#/compact/grid',
      },
      {
        label: 'Stack',
        element: <Stack />,
        path: 'stack/*',
        href: '#/compact/stack',
      },
      {
        label: 'Tabs',
        element: <Tabs />,
        path: 'tabs/*',
        href: '#/compact/tabs',
      },
      {
        label: 'Popover',
        element: <Popover />,
        path: 'popover/*',
        href: '#/compact/popover',
      },
      {
        label: 'Toast',
        element: <Toast />,
        path: 'toast/*',
        href: '#/compact/toast',
      },
    ],
  },
  {
    label: 'Tools',
    data: [
      {
        label: 'YIQ',
        element: <Box />,
        path: 'yiq/*',
        href: '#/compact/yiq',
      },
    ],
  },
];

export default config;
